<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/username.png" class="icon" />
            <input type="text" v-model="username" class="input zczz" placeholder="输入姓名" />
          </div>
          <div class="item">
            <img src="@/assets/zjhm.png" class="icon" />
            <input type="text" v-model="Certificate_code" class="input zczz" placeholder="输入证件号或拍照自动识别" />
            <img src="@/assets/ocr.png" class="icon"  @click="photograph_zz"/>
          </div>
          <div class="item">
            <img src="@/assets/sj_1.png" class="icon" />
            <input type="number" v-model="tel" class="input zczz" placeholder="输入手机号码" />            
          </div>
          <div class="item">
            <img src="@/assets/yzm.png" class="icon" />
            <input type="number" v-model="code" class="input zczz" placeholder="输入验证码" />
            <div class="checkcode" @click="getCode">{{ time === 0?'获取验证码': '获取验证码('+time+'s)' }}</div>
          </div>
        </div>
        <div class="button primary mt" @click="submit">注册</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  timer: '',
  data() {
    return {
      cert_code: '',
      username: '',
      tel: '',
      unionid: '',
      openid:'',
      Certificate_code: '',
      code: '',     
      state:false,
      time: 0
    };
  },
  async created() {

    this.username = this.$route.query.username;
    this.Certificate_code = this.$route.query.Certificate_code;
    this.cert_code = this.$route.query.Certificate_code;
    this.tel = this.$route.query.phone;
    this.unionid = this.$route.query.unionid;
    this.openid = this.$route.query.openid;
    window.sessionStorage.setItem('openid', this.openid); 

    let config = (
      await this.axios.post(
        '/medicines/yd_tlService/jsapisign',
        qs.stringify({
          url: location.href
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: [// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              'checkJsApi',
							'scanQRCode',
							'getLocation', 
							'chooseImage',//拍照或从手机相册中选图接口
							'previewImage',//预览图片接口
							'uploadImage',//上传图片接口
							'downloadImage',//下载图片接口
							'getLocalImgData'
        ] 
    });
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    async getCode() {
      if (this.time !== 0) {
        return;
      }
      if (!isTel(this.tel)) {
        Toast('电话号格式不正确!');
        return;
      }
      let { data } = await this.axios.post('/medicines/yd_tlService/yd_Mobile', qs.stringify({ phone: this.tel }));
      if (data.flag === '0') {
        Toast('发送成功!');
        window.clearInterval(this.timer);
        this.time = 60;
        this.timer = window.setInterval(() => {
          this.time = this.time - 1;
          if (this.time <= 0) {
            this.time = 0;
            window.clearInterval(this.timer);
          }
        }, 1000);
      } else {
        Toast(data.message);
        //Dialog({ message: data.message });
      }
    },
    photograph_zz(){
      //alert("选择照片");
      window.wx.ready(() => {
        //选择照片
        window.wx.chooseImage({
          count: 1, //张数， 默认9
          sizeType: ['compressed'], //建议压缩图
          sourceType: ['album', 'camera'], // 来源是相册(album)、相机(camera)
          //sourceType: ['camera'], // 来源是相册(album)、相机(camera)
          success: res => {
            var localIds = res.localIds.toString();

            //上传至微信服务器
            window.wx.uploadImage({
              localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
              success: res => {
                var serverId = res.serverId; // 返回图片的服务器端 ID         
                this.uploadImg_zz(serverId);

              },
              fail: function() {
                Toast("上传异常");
              }
            });

          },
          fail: function() {
            Toast("选择异常");
          }
        }); 
      });
      
    },
    async uploadImg_zz(serverId) {
      let { data } = await this.axios.post(
        '/medicines/yd_tlService/dj_zz_ocr',
        qs.stringify({
          pzzzid: serverId
        })
      );
      if (data.flag === '0') {
        Toast(data.message);
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 
        this.zzimgurl = testobj.pic_zz;
        this.pic_name = testobj.pic_name;
      }else {
        Toast(data.message);
      }    
    },
    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      if (isEmpty(this.username)) {
        Toast('用户名不可以为空!');
        this.state = false;
        return;
      }
      if (isEmpty(this.Certificate_code)) {
        Toast('证件号码不可以为空!');
        this.state = false;
        return;
      }
      if (!isTel(this.tel)) {
        Toast('电话号格式不正确!');
        this.state = false;
        return;
      }
      if (isEmpty(this.code)) {
        Toast('验证码不可以为空!');
        this.state = false;
        return;
      }

      if (this.cert_code == this.Certificate_code) {
        let { data } = await this.axios.post(
          '/medicines/yd_tlService/yd_MobileReg',
          qs.stringify({ 
            phone: this.tel, 
            mobilecode: this.code, 
            username: this.username, 
            codes: this.Certificate_code, 
            unionid: this.unionid 
          })
        );
        if (data.flag === '0') {
          this.state = false;
          Toast(data.message);
          this.$router.replace({path:'/medical/my', query:{
            openid:this.openid,
            unionid:this.unionid,
            tel:this.tel,
            name:this.username
          }});
        } else {
          this.state = false;
          Toast(data.message);
          //Dialog({ message: data.message });
        }
      }else{
        Dialog.confirm({
          message: '人脸识别证件和填写证件不一致，是否继续操作！'         
        }).then(async () =>{
          let { data } = await this.axios.post(
            '/medicines/yd_tlService/yd_MobileReg',
            qs.stringify({ 
              phone: this.tel, 
              mobilecode: this.code, 
              username: this.username, 
              codes: this.Certificate_code, 
              unionid: this.unionid 
            })
          );
          if (data.flag === '0') {
            this.state = false;
            Toast(data.message);
            this.$router.replace({path:'/medical/my', query:{
              openid:this.openid,
              unionid:this.unionid,
              tel:this.tel,
              name:this.username
            }});
          } else {
            this.state = false;
            Toast(data.message);
            //Dialog({ message: data.message });
          }
        });
        this.state = false;
      }

      

      
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.checkcode {
  position: absolute;
  line-height: 44px;
  height: 44px;
  right: 0;
  top: 35px;
  color: rgba(0, 49, 255, 1);
}
</style>